import { ExpandableContent } from '../../../../shared/components/ExpandableContent';
import { InvoiceForm } from 'features/Ingresos/SalesDocument/IngresoConFactura/domain/Invoice';
import { useCustomFormContext } from 'shared/UI/components/Form/hooks/useCustomFormContext';
import { Box, Link, Notification, SelectorGroup } from '@declarando/design_system';
import { ControlledFormField } from 'shared/UI/components/Form/components/ControlledFormField';
import { useShouldShowNIFIVAQuestion } from './useShouldShowNIFIVAQuestion';
import { useCustomWatch } from 'shared/UI/components/Form/hooks/useCustomWatch';
import { useVIESCheck } from '../VIESChecker/hooks/useVIESCheck';
import { ClientTypeChecks } from 'features/Ingresos/Clientes/domain/ClientType';
// import { useInvoiceFormContext } from 'features/Ingresos/IngresoConFactura/UI/IngresoConFacturaFormView/context/InvoiceFormProvider';

const NIFIVAQuestionContent = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    // const { formBlocked } = useInvoiceFormContext();
    const { resetCheckVIES } = useVIESCheck();

    return (
        <ControlledFormField
            name="client.hasEUVatNumber"
            control={control}
            Component={({ field }) => (
                <SelectorGroup
                    label="¿Tu cliente tiene NIF IVA?"
                    required
                    readOnly
                    tooltipOptions={{
                        title: '¿Qué es el NIF IVA y para qué sirve?',
                        text: 'El NIF IVA es el número de identificación fiscal que se le asigna a un autónomo o empresa para operar dentro de la Unión Europea y poder aplicar las ventajas propias de los intracomunitarios.',
                        link: {
                            text: 'Saber más',
                            href: 'https://ayuda.declarando.es/es/articles/2843194-que-es-el-roi',
                        },
                    }}
                    variant="radio"
                    //NOTE: Always disabled until client edition from new invoice form is implemented
                    // selectors={[
                    //     { label: 'Sí', id: 'yes', checked: field.value === true, disabled: formBlocked.isFormBlocked },
                    //     { label: 'No', id: 'no', checked: field.value === false, disabled: formBlocked.isFormBlocked },
                    // ]}
                    selectors={[
                        { label: 'Sí', id: 'yes', checked: field.value === true, disabled: true },
                        { label: 'No', id: 'no', checked: field.value === false, disabled: true },
                    ]}
                    {...field}
                    onChange={(selectedIds) => {
                        field.onChange(selectedIds[0] === 'yes');
                        resetCheckVIES();
                    }}
                />
            )}
        />
    );
};

const NotificationWhenNIFIVAQuestionIsNegative = () => {
    const { control } = useCustomFormContext<InvoiceForm>();
    const hasEUVatNumber = useCustomWatch({ control, name: 'client.hasEUVatNumber' });
    const clientType = useCustomWatch({ control, name: 'client.clientType' });

    //NOTE: If undefined, it means that the user has not selected any option yet
    const shouldExpand = hasEUVatNumber === false && ClientTypeChecks.isCompanyOrFreelancer(clientType);

    return (
        <ExpandableContent shouldExpand={shouldExpand}>
            <Box marginTop="md">
                <Notification
                    tone="info"
                    title="Si tu cliente está dado de alta en el ROI y no indicas su NIF IVA en el ingreso con factura:"
                    message={
                        <>
                            <div>
                                <span>1. Estarás incumpliendo tus obligaciones fiscales</span>
                            </div>
                            <div>
                                <span>2. Tu cliente pagará un IVA de más que no le corresponde</span>
                            </div>
                            <div style={{ lineHeight: '24px' }}>
                                <Link
                                    href="https://ayuda.declarando.es/es/articles/1062370-como-hacer-una-factura-de-venta-a-una-empresa-o-autonomo-europeo"
                                    underline
                                >
                                    Saber más
                                </Link>
                            </div>
                        </>
                    }
                />
            </Box>
        </ExpandableContent>
    );
};

export const NIFIVAQuestion = () => {
    const { shouldShowNIFIVAQuestion } = useShouldShowNIFIVAQuestion();

    return (
        <>
            <ExpandableContent shouldExpand={shouldShowNIFIVAQuestion}>
                <Box marginTop="md">
                    <NIFIVAQuestionContent />
                </Box>
            </ExpandableContent>
            <NotificationWhenNIFIVAQuestionIsNegative />
        </>
    );
};
