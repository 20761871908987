import { ComponentProps } from 'react';
//import DateWrapper from './Date';
import { Tag } from '@declarando/design_system';
import DateWrapper from 'shared/infra/utils/Date';

interface TagOptions {
    context?: 'navbar' | 'dashboard';
}

export const handleTagDependingOnDate = ({ context = 'navbar' }: TagOptions = {}): {
    children: string;
    variant: ComponentProps<typeof Tag>['variant'];
    size?: string;
} => {
    const size = context === 'dashboard' ? 'medium' : 'small';
    let children = context === 'dashboard' ? '🗓 Inicio 22 abril' : 'Inicio 22 abril';

    const now = DateWrapper.getNow();
    const dias1 = DateWrapper.diffDays(DateWrapper.formatStringToDate('22-04-2025'), now);
    const dias2 = DateWrapper.diffDays(DateWrapper.formatStringToDate('20-06-2025'), now);
    const dias3 = DateWrapper.diffDays(DateWrapper.formatStringToDate('21-06-2025'), now);
    const dias4 = DateWrapper.diffDays(DateWrapper.formatStringToDate('30-06-2025'), now);

    if (dias1 > 0) {
        children = context === 'dashboard' ? '🗓 Inicio 22 abril' : 'Inicio 22 abril';
        return { children, variant: 'neutral', size };
    } else if (dias1 <= 0 && dias2 > 0) {
        children = context === 'dashboard' ? '✏️ En periodo' : 'En periodo';
        return { children, variant: 'success', size };
    } else if (dias3 && dias4 > 0) {
        children = context === 'dashboard' ? '😱️️ Últimos días' : 'Últimos días';
        return { children, variant: 'notification', size };
    }
    return { children: '', variant: '', size };
};
