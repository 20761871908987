import React, { Component } from 'react';
import { MixPanelProfileData } from 'shared/infra/utils/user_events/MixPanelSettings';
import { Box, ButtonSolid, Flex, MainLoader, ModalNotification, P, Section, Toast } from '@declarando/design_system';
import Intercom from '../components/External/Intercom';
import { Planes } from 'shared/domain/constants/Planes';
import DireccionFormPart from '../components/FormParts/DireccionFormPart';
import { Period } from 'shared/domain/constants/Period';
import ContrataModal, { ContrataModalController } from '../components/Popups/ContrataModal';
import RepositoryFactory from '../../infra/repositories/RepositoryFactory';
import GTMData from '../components/External/GTMData';
import JWT from 'shared/infra/utils/JWT';
import AppRoutesRepository from '../../infra/repositories/Locations/AppRoutesRepository';
import { hotjar } from 'react-hotjar';
import { AppLayout } from './AppLayoutStyles';
import { Notifier } from 'shared/infra/utils/Notifier';
import { ModalConfirmationPhone } from 'shared/UI/components/Popups/ModalConfirmationPhone/ModalConfirmationPhone';
import { Modalregularizaciontarjeta } from '../components/ModalRegularizacionTarjeta/ModalRegularizacionTarjeta';
import { retrieveValidatedPhone } from '../components/App/retrieveValidatedPhone';
import { RootContextProvider } from 'shared/UI/context/RootContext/RootContext';
import { AppRouter } from 'routes/AppRouter';
import { IaesHelper } from 'shared/domain/fiscalStatus/iae/IaesHelper';
import { RolesHelper } from '../../stores/RolesHelper';
import { MainContentSection } from 'shared/UI/components/App/MainContentSection';
import { InitialAppState } from '../components/App/InitialAppState';
import { PlanHelper } from '../../stores/PlanHelper';
import MixPanel from 'shared/infra/utils/user_events/MixPanel';
import Date from '../../infra/utils/Date';
import UserV2Repository from '../../infra/repositories/UserV2Repository';
import { FeatureFlags } from 'config/FeatureFlags';
import { Link } from 'react-router-dom';
import { ConditionalNavbar } from './components/ConditionalNavBar';
import { ModalReactivacionCuentaSuccess } from './components/ModalReactivacionCuentaSuccess/ModalReactivacionCuentaSuccess.tsx';
import { Banners } from './components/Banners/Banners';
import { RenderTitleDocument } from './components/RenderTitleDocument';
import { ErrorReporting } from 'shared/infra/error-reporter/ErrorReporting';
import { SentryReporter } from 'shared/infra/error-reporter/SentryReporter';

const errorReporting = ErrorReporting.getInstance(new SentryReporter());
class App extends Component {
    get layout() {
        return 'App';
    }

    constructor(props) {
        super(props);
        AppRouter.history = props.history;

        this.refAddressPopup = null;
        this.repositoryFactory = new RepositoryFactory();
        this.userV2Repository = new UserV2Repository();
        this.userRolesRepository = this.repositoryFactory.getV2Repository();
        this.routesRepository = new AppRoutesRepository();
        this.phoneRepository = this.repositoryFactory.getPhoneRepository();

        this.state = InitialAppState;
        this.planHelper = new PlanHelper(this);
    }

    async loadInitialData() {
        MixPanel.init();
        Notifier.init(this.refs.notifSystem);

        await Promise.all([
            this.loadUserRoles(),
            this.retrieveValidatedPhone(),
            FeatureFlags.loadFlags(),
            this.getRecurrenteData(),
        ]);
        this.loadUserInfo();
    }

    getRecurrenteData() {
        new UserV2Repository().getRecurrenteData().then((recurrenteData) => {
            this.setState({ recurrenteData });
        });
    }

    async componentDidMount() {
        const token = JWT.getToken();
        const tokenData = JWT.parseToken();

        const hasAdminRole = tokenData.roles ? tokenData.roles.includes('ROLE_ADMIN') : false;
        this.setState({
            hasAdminRole,
        });

        if (token) {
            this.loadInitialData();
        } else {
            AppRouter.goLogin();
        }
        hotjar.initialize(902753, 6);
    }

    async retrieveValidatedPhone() {
        const { phoneValidated, rememberedConfirmPhone } = await retrieveValidatedPhone();
        this.setState({
            phoneValidated: phoneValidated.data,
            rememberedConfirmPhone: Boolean(rememberedConfirmPhone),
            isVerificationPhoneModalOpen:
                !rememberedConfirmPhone || phoneValidated.data.vencimiento_telefono === 'semana3',
        });
    }

    loadUserRoles() {
        this.userRolesRepository.getUserRoles().then((response) => {
            if (response) {
                sessionStorage.setItem('tmp_roles', JSON.stringify({ roles: response }));
                this.setState({
                    roles: response,
                });
            }
        });
    }

    checkIsOnboardingActive(profileData) {
        return profileData.onboarding?.isActive;
    }

    async loadUserInfo() {
        const profileData = await this.userV2Repository.getProfile();

        if (this.layout === 'App' && this.checkIsOnboardingActive(profileData)) {
            if (!this.state.hasAdminRole) {
                return AppRouter.goSetupNoAFFA(profileData.onboarding.step);
            }
        }

        if (profileData) {
            const userInfo = {
                id: profileData.id,
                name: profileData.identity.firstName,
                isAgent: profileData.isAgent,
                plan: profileData.plan,
                registerDate: profileData.registerDate,
                demoUntilDate: profileData.demoUntilDate,
            };

            errorReporting.setUserInfo(userInfo);

            const userIdentity = {
                ...profileData.identity,
                prefijo: profileData.identity.prefijo ? profileData.identity.prefijo : 34,
            };
            const userAddress = profileData.address;
            const userStatus = profileData.fiscal;
            /*if (Env.isLocal('adrianM')) {
const iae = d.fiscal.iaes[0];
iae.subgrupoNombre = TiposIAE.ganaderoEngorde;
userStatus.iaes = [...d.fiscal.iaes, iae];
}*/
            const userWorkAddress = profileData.workAddress;

            let loadsMoreData = userInfo.plan.label !== Planes.FREE;
            const fechaRecurrenciaProgramada = profileData.fechaRecurrenciaProgramada;
            const blockedModalRegularizarTarjeta = fechaRecurrenciaProgramada
                ? Date.diffDaysFromNow(fechaRecurrenciaProgramada) < 90
                : false;

            this.setState(
                {
                    fechaRecurrenciaProgramada,
                    regularizarTarjetaConCoF: profileData.regularizarTarjetaConCoF, // true
                    blockedModalRegularizarTarjeta,
                    showModalVisaChange: false,
                    userInfo,
                    userIdentity,
                    userStatus,
                    userAddress,
                    userWorkAddress,
                    showMainLoader: false,
                    loadingData: loadsMoreData,
                    perfilBloqueado: profileData.perfilBloqueado,
                    onboarding: profileData.onboarding,
                    isPeriodoDemo: profileData.isPeriodoDemo,
                    diasRestantesDemo: profileData.diasRestantesDemo,
                },
                () => {
                    if (this.isEmptyAddress() && this.planHelper.isPaid()) {
                        this.setState({
                            popupGoProfile: true,
                        });
                    }

                    if (loadsMoreData) {
                        this.updateResumeData();
                    }

                    //this.props.changeLanguage(userIdentity.language);
                },
            );
        }
        this.setState({
            loadedUserInfo: true,
        });
    }

    updateResumeData = () => {
        this.userV2Repository.getResumeStats(Period.LAST_MONTHS).then((response) => {
            const d = response.data;
            this.setState({
                beneficio: d.beneficio,
                ahorro: d.ahorro,
                objetivoAhorro: d.objetivoAhorro,
                loadingData: false,
            });
        });
    };

    isContrataPages = () => {
        return this.props.location.pathname.indexOf('/contrata/') > -1;
    };

    isEmptyAddress = () => {
        return parseInt(this.state.userAddress.poblacion_id) === 0;
    };

    // State updaters

    updateUserIdentity = (field, value) => {
        this.setState({
            userIdentity: { ...this.state.userIdentity, [field]: value },
        });
    };

    updateAsyncUserIdentity = (field, value) => {
        return new Promise((resolve) => {
            this.setState(
                {
                    userIdentity: { ...this.state.userIdentity, [field]: value },
                },
                () => {
                    resolve(true);
                },
            );
        });
    };

    updateUserIdentityFull = (identity) => {
        this.setState({
            userIdentity: identity,
        });
    };

    updateUserStatus = (field, value, cbk) => {
        this.setState(
            {
                userStatus: { ...this.state.userStatus, [field]: value },
            },
            function () {
                if (typeof cbk === 'function') {
                    cbk();
                }
            },
        );
    };

    updateUserAddress = (field, value, cbk) => {
        this.setState(
            {
                userAddress: { ...this.state.userAddress, [field]: value },
            },
            function () {
                if (typeof cbk === 'function') {
                    cbk();
                }
            },
        );
    };

    updateUserAddressFull = (address, cbk) => {
        this.setState(
            {
                userAddress: address,
            },
            function () {
                if (typeof cbk === 'function') {
                    cbk();
                }
            },
        );
    };

    updateUserWorkAddress = async (field, value, cbk) => {
        await this.setState(
            {
                userWorkAddress: { ...this.state.userWorkAddress, [field]: value },
            },
            function () {
                if (typeof cbk === 'function') {
                    cbk();
                }
            },
        );
    };

    updateUserWorkAddressFull = (workAddress, cbk) => {
        this.setState(
            {
                userWorkAddress: workAddress,
            },
            function () {
                if (typeof cbk === 'function') {
                    cbk();
                }
            },
        );
    };

    // State savers
    storeUserIdentity = () => this.userV2Repository.storeIdentity(this.state.userIdentity);
    storeUserStatus = () => this.userV2Repository.storeStatus(this.state.userStatus);
    storeUserAddress = () =>
        this.userV2Repository.storeAddress({
            address: this.state.userAddress,
        });
    storeUserWorkAddress = () =>
        this.userV2Repository.storeWorkAddress({
            workAddress: this.state.userWorkAddress,
        });

    openPopUpContrata = () => ContrataModalController.modal.showModal();
    closePopUpContrata = () => ContrataModalController.modal.closeModal();

    openPopUpNotEnabled = () => this.setState({ popupNotEnabledOpen: true });
    closePopUpNotEnabled = () => this.setState({ popupNotEnabledOpen: false });

    confirmedPhone = (prefijo, phone) => {
        return this.setState({
            phoneValidated: { validado: true },
            userIdentity: { ...this.state.userIdentity, prefijo, phone },
        });
    };

    setOnboarding = (onboarding) => {
        this.setState({ onboarding: onboarding });
    };

    getContextProperties = () => {
        this.spreads = {
            ...this.props,
        };

        return {
            ...this.spreads,

            recurrenteData: this.state.recurrenteData,
            perfilBloqueado: this.state.perfilBloqueado,
            phoneValidated: this.state.phoneValidated,
            userIdentity: this.state.userIdentity,
            userInfo: this.state.userInfo,
            userStatus: this.state.userStatus,
            userAddress: this.state.userAddress,
            userWorkAddress: this.state.userWorkAddress,
            iaesChanged: this.state.iaesChanged,
            isPeriodoDemo: this.state.isPeriodoDemo,
            diasRestantesDemo: this.state.diasRestantesDemo,
            updateUserIdentity: this.updateUserIdentity,
            updateAsyncUserIdentity: this.updateAsyncUserIdentity,
            updateUserIdentityFull: this.updateUserIdentityFull,
            updateUserStatus: this.updateUserStatus,
            updateUserAddress: this.updateUserAddress,
            updateUserAddressFull: this.updateUserAddressFull,
            updateUserWorkAddress: this.updateUserWorkAddress,
            updateUserWorkAddressFull: this.updateUserWorkAddressFull,
            storeUserIdentity: this.storeUserIdentity,
            storeUserStatus: this.storeUserStatus,
            storeUserAddress: this.storeUserAddress,
            storeUserWorkAddress: this.storeUserWorkAddress,
            iaesHelper: new IaesHelper(this),
            rolesHelper: new RolesHelper(this),
            openPopUpContrata: this.openPopUpContrata,
            closePopUpContrata: this.closePopUpContrata,
            confirmedPhone: this.confirmedPhone,
            setOnboarding: this.setOnboarding,
            planHelper: this.planHelper,
            loadUserInfo: () => this.loadUserInfo(),
            onboarding: this.state.onboarding,
            regularizacionTarjeta: {
                fechaRecurrenciaProgramada: this.state.fechaRecurrenciaProgramada,
                regularizarTarjetaConCoF: this.state.regularizarTarjetaConCoF,
                blockedModalRegularizarTarjeta: this.state.blockedModalRegularizarTarjeta,
                showModalVisaChange: this.state.showModalVisaChange,
                displayModalVisaChangeHandler: () => this.setState({ showModalVisaChange: true }),
                hideModalVisaChangeHandler: () =>
                    this.setState({ showModalVisaChange: false, regularizarTarjetaConCoF: false }),
            },
        };
    };

    render() {
        return (
            <RootContextProvider value={this.getContextProperties()}>
                <Banners />
                <RenderTitleDocument />

                {this.state.showMainLoader ? <MainLoader label="" /> : null}
                {this.state.loadedUserInfo && (
                    <AppLayout className="App">
                        <ModalReactivacionCuentaSuccess />
                        <ConditionalNavbar
                            isAccountingDisabledForFree={this.planHelper.isAccountingDisabledForFree()}
                            openPopUpContrata={this.openPopUpContrata}
                            openPopUpNotEnabled={this.openPopUpNotEnabled}
                            roles={this.state.roles}
                        />

                        <MainContentSection
                            onScrollMain={this.onScrollMain}
                            wide={this.props.wide}
                            loadedUserInfo={this.state.loadedUserInfo}
                            routes={this.props.routes}
                            roles={this.state.roles}
                            spreads={this.spreads}
                            userStatus={this.state.userStatus}
                            userInfo={this.state?.userInfo}
                        />
                        <div style={{ position: 'fixed', bottom: '4px', left: '5px' }}>
                            <Link style={{ color: 'grey' }} to="/about/versiones">
                                v3.22
                            </Link>
                        </div>
                    </AppLayout>
                )}
                <GTMData userInfo={this.state.userInfo} />

                {/*Modales*/}
                {this.state.phoneValidated.validado === false &&
                    this.state.phoneValidated.vencimiento_telefono !== 'semana1' && (
                        <ModalConfirmationPhone
                            prefijo={this.state.userIdentity.prefijo}
                            phone={String(this.state.userIdentity.phone)}
                            blocked={this.state.phoneValidated.vencimiento_telefono === 'semana3'}
                            show={this.state.isVerificationPhoneModalOpen}
                            plan={this.state.userInfo?.plan.label}
                            limitDate={this.state.phoneValidated.fecha_limite}
                            onValidatedPhone={(prefijo, phone) => this.confirmedPhone(prefijo, phone)}
                            cancelAction={() => {
                                this.setState({ isVerificationPhoneModalOpen: false });
                            }}
                        />
                    )}
                {this.state.phoneValidated.validado === false &&
                    this.state.phoneValidated.vencimiento_telefono === 'semana2' && (
                        <Box backgroundColor="neutral.Dark70">
                            <Flex justifyContent={'space-between'}>
                                <P textColor="white" weight="bold" margin="20px">
                                    Te quedan {this.state.phoneValidated.semana2_dias_restantes} días para verificar tu
                                    número de móvil
                                </P>
                                <ButtonSolid
                                    marginRight="20px"
                                    size="sm"
                                    onClick={() => {
                                        this.setState({ isVerificationPhoneModalOpen: true });
                                    }}
                                >
                                    Ir a verificar
                                </ButtonSolid>
                            </Flex>
                        </Box>
                    )}
                <Modalregularizaciontarjeta
                    regularizarTarjetaConCoF={this.state.regularizarTarjetaConCoF}
                    fechaRecurrenciaProgramada={this.state.fechaRecurrenciaProgramada}
                    blockedModalRegularizarTarjeta={this.state.blockedModalRegularizarTarjeta}
                />
                <ContrataModal />

                <ModalNotification
                    isOpen={this.state.popupNotEnabledOpen}
                    handleClose={this.closePopUpNotEnabled}
                    title={'Sección Desactivada'}
                    {...this.spreads}
                >
                    <>
                        Según tus indicaciones, tienes la contabilidad desactivada. Si quieres activarla escríbenos un
                        correo.
                    </>
                </ModalNotification>

                <ModalNotification
                    title={<>Completar información</>}
                    isOpen={this.state.popupGoProfile}
                    handleClose={() => {
                        if (this.refAddressPopup.isValidated()) {
                            this.storeUserAddress().then(() => {
                                Notifier.notifySuccess();
                                window.location.reload();
                            });
                        }
                    }}
                    labelAction={'Guardar'}
                >
                    <Section>
                        <P>
                            Necesitamos que nos digas dónde vives habitualmente para que podamos hacer los cálculos
                            internos.
                        </P>
                        <DireccionFormPart
                            formName={'LivingPlace'}
                            address={this.state.userAddress}
                            updateField={this.updateUserAddress}
                            ref={(ref) => {
                                if (ref) this.refAddressPopup = ref;
                            }}
                            {...this.spreads}
                        />
                    </Section>
                </ModalNotification>

                <Toast ref="notifSystem" />

                {!this.state.loadingData && this.planHelper.isPaid() && (
                    <Intercom
                        userInfo={this.state.userInfo}
                        userIdentity={this.state.userIdentity}
                        userStatus={this.state.userStatus}
                        ahorro={this.state.ahorro}
                        beneficio={this.state.beneficio}
                    />
                )}
                <MixPanelProfileData />
            </RootContextProvider>
        );
    }
}

export default App;
